.jodit-workplace {
	min-height: 200px !important;
  border: none !important;
	background: #121212 !important;
}

.jodit-workplace a {
	@apply !text-blue-600 !underline;
}

.jodit-ui-input__input,
.jodit-ui-checkbox__label {
	color: black !important;
}

.jodit-dialog__panel {
	color: black !important;
}

.jodit-react-container ol {
  list-style: inside decimal;
}

.jodit-react-container ul {
  list-style: inside;
}

jodit-react-container li {
  padding-left: 4px;
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
  @apply !bg-background;
}

.jodit-container:not(.jodit_inline) {
	@apply !border-border;
  transition: border-color 300ms;
}

.jodit-container:not(.jodit_inline):hover {
  transition: border-color 300ms;
  @apply !border-border;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar__box:not(:empty),
.jodit-container:not(.jodit_inline) .jodit-workplace+.jodit-status-bar:not(:empty) {
  border-color: transparent !important;
  @apply !bg-background;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-button:hover {
  @apply !bg-background;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-button__button:hover:not([disabled]) {
  @apply bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-button__trigger:hover:not([disabled]) {
  @apply !bg-gray-100 dark:bg-gray-700
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-button__icon svg {
  @apply !fill-black dark:!fill-white;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-button_with-trigger_true:hover:not([disabled]) {
  @apply !bg-gray-200 dark:bg-gray-600
}

.jodit-container:not(.jodit_inline) .jodit-toolbar__box:not(:empty) {
  @apply !bg-background;
}

.jodit-container:not(.jodit_inline) .jodit-toolbar-editor-collection_mode_horizontal:after {
  height: 0;
  background-color: transparent !important;
}
